<template>
  <div class="containter">
    <div class="content">
      <div class="title">业绩查询系统</div>
      <div class="input-container">
        <van-field label="" v-model="phone" center placeholder="请输入手机号" class="input-class-mobile" />
        <van-field label="" v-model="code" center placeholder="验证码" class="input-class-code">
          <template #button>
            <div class="msg-text-btn" v-bind:disabled="messageCodeDisabled" v-on:click="getCode">
              <van-count-down :time="time" class="count-down-class" v-if="countDownShow" format="sss后获取" @finish="countDownfinish" />{{ sendMsgText }}</div>
          </template>
        </van-field>
        <van-button type="primary" size="big" v-bind:disabled="sumbitDisabled" class="sumbit-button" color="#784CFA" v-on:click="sumbit">立即登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import API from "@/common/axios";

export default {
  data() {
    return {
      phone: '',
      code: '',
      key: '', //用于校验验证码是否正确
      time: 0
    };
  },
  mounted() {
    document.title = "登录";
    let accountInfo = localStorage.getItem('groupManagerAccountInfo');
    if (accountInfo) {
      this.$router.replace('/groupManager/home');
      return;
    }
  },
  watch: {},

  methods: {
    countDownfinish: function () {
      this.time = 0;
    },
    /**
     * 校验手机号码是11位，并且是数字
     * @param mobile
     * @returns {boolean}
     */
    validateMobileL: function (mobile) {
      const regex = /^\d{11}$/;
      return regex.test(mobile);
    },
    /**
     * 获取验证码
     */
    getCode: function (e) {
      if (this.time > 0) {
        return;
      }
      this.time = 60 * 1000;
      API({
        url: "/h5/team/getVerificationCode.do",
        data: {
          phone: this.phone,
        }
      }).then(res => {
        if (res.success == 1) {
          this.key = res.data;
        } else {
          Toast(res.error.message);
        }
      }).catch(res => {
        Toast("获取验证码错误");
      });
    },
    /**
     * 提交登录
     */
    sumbit: function () {
      let _this = this;
      API({
        url: "/h5/team/login.do",
        data: {
          phone: this.phone,
          code: this.code,
          key: this.key
        }
      }).then(res => {
        if (res.success == 1) {
          //包含userId和token信息
          Toast("登录成功");
          res.data['phone'] = this.phone;
          localStorage.setItem('groupManagerAccountInfo', JSON.stringify(res.data));
          setTimeout(() => {
            _this.$router.replace('/groupManager/home');
          }, 1000);
        } else {
          Toast(res.error.message);
        }
      }).catch(res => {
        Toast("登录失败");
      });
    }


  },
  computed: {
    messageCodeDisabled: function () {
      return !((this.validateMobileL(this.phone)) && this.time == 0);
    },
    sumbitDisabled: function () {
      return !((this.validateMobileL(this.phone)) && this.code && this.key);
    },
    countDownShow: function () {
      return this.time > 0;
    },
    sendMsgText: function () {
      if (this.time > 0) {
        return "";
      } else {
        return "|  获取验证码";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.containter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background-color: #F5F5F7;
  min-height: 100%;
  background-image: url('~@/assets/property_bg.png');
  background-size: 100% 302px;
  background-repeat: no-repeat;
}

.title {
  color: #333333;
  font-size: 24px;
  text-align: center;
  margin-top: 92px;
  margin-bottom: 50px;
}

.content {}

.input-class-mobile {
  background: #F7F7F7;
  border-radius: 10px;
  width: 319px;
  height: 55px;
  border: #333333;
  margin-top: 26px;
}

.input-class-code {
  background: #F7F7F7;
  border-radius: 10px;
  margin-top: 21px;
  width: 319px;
  height: 55px;
  border: #333333;
}

.sumbit-button {
  width: 319px;
  height: 50px;
  background: #784CFA;
  border-radius: 10px;
  margin-top: 21px;
}

.bg_view {
  background-color: #F5F5F7;
  min-height: 100%;
  background-image: url('~@/assets/property_bg.png');
  background-size: 100% 302px;
  background-repeat: no-repeat;
}

.input-container {
  width: 351px;
  height: 254px;
  background: #FFFFFF;
  border-radius: 21px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.count-down-class {
  color: #784CFA;
}

.msg-text-btn {
  width: 80px;
  height: 100%;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #784CFA;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.msg-text-btn[disabled] {
  color: #999999;
  pointer-events: none;
  opacity: 0.5;
}

msg-text-btn:not([disabled]) {
  color: #784CFA;
}

.van-cell::after {
  content: "";
  display: none;
  /* 或者设置为其他不会显示的属性值 */
  /* 如果有其他属性如 background、border 等也可以一并清除 */
}
</style>